<template>
  <init-data />
  <breadcrumbs />
  <hr>
  <class-node />
  <mask-node />
  <list-node />
  <conf-node />
  <prod-node />
</template>
