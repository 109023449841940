<template>
  <div :class="'node' + ' nt-' + node.nodetype">
    <span class="label">{{ node.desc }}</span>
    <span v-if="node.values" class="value">: {{ node.values.map(value => value.desc).join(',') }}</span>
    <div class="children">
      <nested-data v-for="child in node.nodes" :key="child.nodename" :node="child" />
      <nested-data v-for="child in node.props" :key="child.nodename" :node="child" />
    </div>
  </div>
</template>

<script lang="ts">
import * as pf from '@amirada/lib-shared';
export default defineComponent({
  props: {
    node: {
      type: Object as PropType<pf.Node>,
      required: true
    }
  },
  setup () {
    return { pf: useNuxtApp().$pf };
  }
});
</script>
<style lang="scss">

.children {
  margin-left: 8px;
}
.node {
  margin: 1px;
  border: 1px solid #666666;
}
</style>
      
