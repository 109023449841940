<template>
  <div v-if="node.nodetype === NodeType.nt_SearchFilter" class="filter-control">
    <div class="filter-caption">{{ node.desc }}</div>
    <div class="filter-items">
      <label v-for="value of node.values" :key="value.name" class="filter-item hot" :for="value.rowguid+'-checkbox'">
        <input :id="value.rowguid+'-checkbox'" v-model="checked" type="checkbox" :value="value.rowguid" @change="handleChange()">
        {{ value.desc }} ({{ value.hits || 0 }})
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { NodeType } from '@amirada/lib-shared';
import type { Node, Value } from '@amirada/lib-shared';
import type { PropType } from 'vue';
import { defineEmits } from 'vue';

export default defineComponent({
  props: {
    node: {
      type: Object as PropType<Node>,
      required: true
    },
    psx: {
      type: Object as PropType<Record<string, string[]>>,
      required: true
    }
  },
  emits: ["change"],
  setup (props, ctx) {
    const checked = ref(props.psx[props.node.nodename] || []);
    const handleChange = () => {
      if (props.node.props?.length) {
        ctx.emit('change', { prop: props.node, values: checked.value });
      }
    };
    return { pf: useNuxtApp().$pf, NodeType, checked, handleChange };
  }
});
</script>

<style lang="scss" scoped>
.filter-control {
    border: 1px solid var(--color4);
    /* padding: 1px; */
    margin: 1px;
}
.filter-caption {
  border: 1px solid var(--color4);
  padding: 2px;
  font-weight: bold;
}
.filter-items {
  border: 1px solid var(--color4);
  white-space: nowrap;
  background-color: #FFFFFF;
}
.filter-control:hover .filter-items {
  display: block;
}
.filter-item {
  display: block;
  border: 1px solid var(--color7);
  white-space: nowrap;
  cursor: pointer;
    
  &:hover {
    border: var(--border-width) solid var(--bg-color-dark);
    color: var(--font-color-light);
    background-color: var(--bg-color-dark);
  }
   > label {
    white-space: nowrap;
    .disabled {
      opacity: .3;
    }
  }
} 
</style>
      
