<template>
  <div v-if="opsData" class="combo">
    <b>{{ type }}:{{ getSelected() }}</b>
    <div class="combo-items">
      <div
        v-for="(bit, key) of opsData" :key="key" :class="'combo-item hot' + (isSelected(bit) ? ' selected' : '')" 
        @click="toggleSelected(bit)" v-html="key"
      />
    </div>
  </div>
</template>
  
<script lang="ts">
import { COPS as cops } from '../constants/cops';
export default defineComponent({
  props: {
    type: {
      type: String as PropType<'dops'|'cops'>,
      required: true
    }
  },
  setup (props) {
    const app = useNuxtApp();
    const source = {
      get dops () { return (app.$pf.store.data.initData || {}).dopsData || { optionsOn: 1 }; },
      cops
    };
    const opsData = ref<{[key: string]: number}>();
    opsData.value = source[props.type];
    if (props.type === 'dops') {
      watch(() => app.$pf.store.data.initData, (data) => { opsData.value = source.dops; });
    }
    const getSelected = () => app.$pf.params.curr.query[props.type] || 0;
    const isSelected = (bit: number) => getSelected() & bit;
    const toggleSelected = (bit: number) => { app.$pf.util.setOps(props.type, bit ^= getSelected()); };
    return { app, getSelected, isSelected, toggleSelected, opsData };
  }
});
</script>
<style lang="scss">
/*
.combo {
    display: block;
    border: 1px solid #666666;
    background-color: white;
    cursor: pointer;
    margin: 1px;
    .combo-items {
      display: none;
      position:absolute;
      background-color: white;
      min-width: 100%;
      .combo-item {
        border: 1px solid #666666;
        padding: 2px;
      }
      .combo-item.selected {
          color: #FFFFFF;
        background-color: #999999;
      }
    }
}
.combo:hover {
  .combo-items {
      display: block;
  }
}
*/
</style>
        
  
