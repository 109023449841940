<template>
  <template v-if="data.initData">
    <h1>Init</h1>
    <div class="init-options">
      <combo-lang type="lcda" />
      <combo-rest type="rcco" />
      <combo-rest type="rcap" />
      <combo-rest type="rcst" />
      <combo-bits type="dops" />
      <combo-bits type="cops" />
    </div>
  </template>
</template>
  
<script lang="ts">
export default defineComponent({
  setup () {
    return { data: useNuxtApp().$pf.store.data };
  }
});
</script>
<style lang="scss">
.init-options {
    display: flex;
    flex-flow: row;
    border: 1px solid #666666;
    position: relative;
    /* height: 1.2rem; */
}
.init-options > * {
    flex: auto;
    position: relative;
}
</style>
        
  
