<template>
  <template v-if="pf.store.data?.pathData">
    <template v-for="(node, index) in pf.store.data.pathData" :key="node.nodename">
      <b v-if="index"> &gt; </b>
      <!-- just a test with utils nodesToPath. not for production -->
      <router-link :to="'/'+pf.params.curr.lcda+'/'+pf.params.curr.rcco+'/'+nodesToPath(pf.store.data.pathData.slice(1, index+1))">
        <span> {{ node.desc }} </span>
      </router-link>
    </template>
  </template>
</template>

<script lang="ts">
export default defineComponent({
  setup () {
    return { pf: useNuxtApp().$pf };
  }
});
</script>
