<template>
  <div v-if="app.$pf.store.data.initData" class="combo">
    <b>{{ type }}:{{ getSelected() }}</b>
    <div class="combo-items">
      <div 
        v-for="node of app.$pf.store.data.initData.restData[type]" 
        :key="node.id" :class="'combo-item hot' + (isSelected(node) ? ' selected' : '')"
        @click="app.$pf.util.setRestriction(type, node.cod)" v-html="node.desc"
      />
    </div>
  </div>
</template>
  
<script lang="ts">
import * as pf from '@amirada/lib-shared';
export default defineComponent({
  props: {
    type: {
      type: String as PropType<keyof pf.RestData>,
      required: true
    }
  },
  setup (props) {
    const router = useRouter();
    const app = useNuxtApp();
    function isSelected (node: any) {
      const initData = app.$pf.store.data.initData;
      const currRestCode = (initData ? (initData[props.type] || '') : '') as string;
      return initData ? (currRestCode.toLowerCase() === node.cod.toLowerCase()) : false;
    }
    function getSelected () {
      const initData = app.$pf.store.data?.initData;
      return initData && initData[props.type];
    }
    return { app, isSelected, getSelected };
  }
});
</script>
        
  
