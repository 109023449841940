<template>
  <div v-if="pf.store.data.classData && pf.store.data.currentData === pf.store.data.classData" class="web-class">
    <h1>Class</h1>
    <h4 v-html="pf.store.data.classData.desc" />
    <div class="items">
      <router-link v-for="node in pf.store.data.classData.nodes" :key="node.nodename" :to="pf.util.nodeToPath(node)">
        <div class="item" v-html="node.desc" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup () {
    return { pf: useNuxtApp().$pf };
  }
});
</script>
<style>
.web-class {
  .items {
    display: flex;
    flex-flow: row;
    border: 1px solid #666666;
    padding: 1px;
    /* justify-content: center; */
    .item {
      flex: auto;
      border: 1px solid #666666;
      margin: 1px;
      padding: 2px;
    }
  }
}
</style>
  
