<template>
  <template v-if="app.$pf.store.data.detailData && app.$pf.store.data.configData === app.$pf.store.data.currentData">
    <h1>Conf</h1>
    <nested-data :node="app.$pf.store.data.detailData" />
  </template>
</template>

<script lang="ts">
export default defineComponent({
  setup () {
    return { app: useNuxtApp() };
  }
});
</script>
      
