<template>
  <div v-if="pf.store.data.maskData && pf.store.data.configData === pf.store.data.currentData" class="mask">
    <h1>Mask</h1>
    <div class="mask-items">
      <div v-for="node of pf.store.data.maskData.nodes" :key="node.nodename" class="mask-item">
        <template v-if="node.nodetype === NodeType.nt_LogicGroup">
          <div :key="node.nodename" class="mask-group">
            <h3 class="mask-group-caption">{{ node.desc }}</h3>
            <MaskFilter v-for="child of node.nodes" :key="child.nodename" :node="child" :psx="psxObj" @change="handleChange" />
          </div>
        </template>
        <template v-else-if="node.nodetype === NodeType.nt_SearchFilter">
          <MaskFilter :key="node.nodename" :node="node" :psx="psxObj" @change="handleChange" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { NodeType } from '@amirada/lib-shared';
import type { Node } from '@amirada/lib-shared';
export default defineComponent({
  setup (props) {
    const { $pf: pf, $router } = useNuxtApp();

    const psxObj: Record<string, string[]> = {};
    (pf.params.curr.query.psx || '').split(',').forEach((psxStr) => {
      if (psxStr) {
        const spxTuple = psxStr.split('|');
        psxObj[spxTuple[0]] = spxTuple.slice(1);
      }
    });

    const handleChange = (filterSelectio: {prop: Node, values: string[]}) => {
      filterSelectio.values.length 
        ? psxObj[filterSelectio.prop.nodename] = filterSelectio.values 
        : delete psxObj[filterSelectio.prop.nodename];
      const psx = Object.entries(psxObj).map(([prop, values]) => `${prop}|${values.join('|')}`).join(',');
      const query = { ...pf.params.curr.query };
      query.psx = psx || undefined;
      $router.push({
        path: pf.params.curr.href,
        query
      });
    };
    
    return { pf, NodeType, handleChange, psxObj };
  }
});
</script>

<style lang="scss">
.mask-group-caption {
  margin: 0px;
}
.mask {
  .mask-items {
    display: flex;
    flex-flow: row;
  }
  .mask-items > * {
    background-color: rgb(233, 233, 233);
    margin: 2px;
    padding: 2px;
  }
}
</style>
      
