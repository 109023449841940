<template>
  <div v-if="app.$pf.store.data.initData" class="combo">
    <b>lcda:{{ getSelected() }}</b>
    <div class="combo-items">
      <div 
        v-for="value of app.$pf.store.data.initData.langData" 
        :key="value.id" :class="'combo-item hot' + (isSelected(value.cod) ? ' selected' : '')"
        @click="app.$pf.util.setLanguage(value.cod)" v-html="value.desc"
      />
    </div>
  </div>
</template>
  
<script lang="ts">
export default defineComponent({
  setup () {
    const app = useNuxtApp();
    const getSelected = () => ((app.$pf.store.data.initData || {})['lcda'] || '') as string;
    const isSelected = (cod: string) => getSelected().toLowerCase() === cod.toLowerCase();
    return { app, getSelected, isSelected };
  }
});
</script>
<style lang="scss">
/*
.combo {
    display: block;
    border: 1px solid #666666;
    background-color: white;
    cursor: pointer;
    margin: 1px;
}
.combo-items {
    display: none;
}
.combo:hover {
    .combo-items {
        display: block;
    }
}
.combo-item {
    border: 1px solid #666666;

    padding: 2px;
}
.combo-item.selected {
    color: #FFFFFF;
   background-color: #999999;
}
*/
</style>
        
  
