<template>
  <div v-if="pf.store.data.listData && pf.store.data.configData === pf.store.data.currentData" class="list">
    <h1>List</h1>
    {{ pf.store.data.listData.desc + ' ' + pf.store.data.listData.nodetype }}<br>
    <table>
      <thead>
        <tr v-for="(row, i) in thead" :key="i">
          <th v-for="td in row" :key="td.desc" :colspan="td.colspan" :rowspan="td.rowspan" v-html="td.desc" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="productNode of productNodes" :key="productNode.nodename" class="hot" @click="pf.util.navigateToNode(productNode)">
          <th><router-link :to="pf.util.nodeToPath(productNode)">{{ productNode.desc }}</router-link></th>
          <td v-for="propNode in propNodes" :key="propNode.nodename">
            {{ (productNode.values || []).filter(value => value.id_prop === propNode.id).map(value => value.desc).join(',') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import * as pf from '@amirada/lib-shared';
export default defineComponent({
  setup (props) {
    const app = useNuxtApp();
    const data = app.$pf.store.data;

    const extractProps = (node: pf.Node): Array<pf.Node> => {
      if (node.nodetype === 2) { return [node]; }
      const result: Array<pf.Node> = [];
      (node.props || node.nodes || []).forEach((node) => {
        Array.prototype.push.apply(result, extractProps(node));
      });
      return result;
    };

    type td = {desc: string, colspan?: number, rowspan?: number};
    const thead = ref<td[][]>();
    const propNodes = ref<pf.Node[]>();
    const productNodes = ref<pf.Node[]>();



    watch(() => data.currentData, (currentData) => {
      if (data.configData && currentData !== data.configData) {
        data.listData = undefined;
      }
    });

    watch(() => data.listData, (listData) => {
      if (listData) {
        const theadArr: td[][] = [];

        const createHeader = (nodes: Array<pf.Node>): td[][] => {
          let childNodes: Array<pf.Node> = [];
          const row: td[] = [];
          theadArr.push(row);
          nodes.forEach((node) => {
            row.push({ desc: node.desc, colspan: extractProps(node).length });
            childNodes = [...childNodes, ...(node.props || node.nodes || [])];
          });
          if (childNodes.length) {
            createHeader(childNodes);
          } else {
            // Add empty th over all thead rows for item name in tbody row
            theadArr[0].unshift({ desc: '', rowspan: theadArr.length });
          }
          return theadArr;
        };

        thead.value = createHeader(listData.header);

        const propNodesArr: pf.Node[] = [];
        listData.header.forEach((node) => {
          Array.prototype.push.apply(propNodesArr, extractProps(node));
        });
        propNodes.value = propNodesArr;
        productNodes.value = listData.nodes;
      } else {
        thead.value = [];
        propNodes.value = [];
        productNodes.value = [];
      }
    });


    return { pf: app.$pf, thead, propNodes, productNodes };
  }
});
</script>

<style lang="scss" scoped>
.list {
  table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    th { 
        /* same as caption */
        /*border: var(--border-width) solid var(--color1); */
        background-color: var(--bg-color-dark);
        color: var(--font-color-light);
        font-weight: bold;
        flex: none !important;
        width: 100%;
        text-align: center;
    }
    tbody > tr {
        border: var(--border-width) solid var(--color2);
        cursor: pointer;
    }
    tbody > tr:hover {
        border: var(--border-width) solid var(--bg-color-dark);
        background-color: var(--bg-color-dark);
        color: var(--font-color-light);
    }
    td {
        border:var(--border-width) solid var(--color3);
    }
    th, td  {
        padding: var(--padding);
        margin: var(--margin);
    }
  }
}
</style>
      
