<template>
  <div>
    <h1>DevClient1</h1>
    <pfinder />
  </div>
</template>

<script lang="ts">


export default defineComponent({
  setup () {
    const router = useRouter();
    const app = useNuxtApp();
    const params = router.currentRoute.value.params;
    const qlx = params.slugs[params.slugs.length - 1];

    return { app };
  }
});
</script>

<style lang="scss">
.link {
  text-decoration: none;
  color: black;
}
.router-link-active {
  color: blue;
}
.router-link-exact-active  {
  font-weight: bold;
}
</style>
